export const Gender = ['男性', '女性', '其它'];
export const Occupation = [
  '自由業',
  '學生',
  '建築營造',
  '金融商業',
  '農林漁牧',
  '教育文化',
  '新聞傳媒',
  '宗教公共',
  '餐旅服務',
  '資訊科技',
  '體育',
  '醫事',
  '交通運輸',
];
export const City = [
  '臺北市',
  '基隆市',
  '新北市',
  '連江縣',
  '宜蘭縣',
  '新竹市',
  '新竹縣',
  '桃園市',
  '苗栗縣',
  '臺中市',
  '彰化縣',
  '南投縣',
  '嘉義市',
  '嘉義縣',
  '雲林縣',
  '臺南市',
  '高雄市',
  '澎湖縣',
  '金門縣',
  '屏東縣',
  '臺東縣',
  '花蓮縣',
];
export const Education = ['國中以下', '高中職', '學士', '碩士以上'];
export const Marriage = ['未婚', '已婚', '離異', '喪偶', '分居'];
export const Interest = [
  '戶外活動',
  '購物時尚',
  '餐飲美食',
  '旅遊',
  '音樂影視',
  '文化藝術',
  '健身保健',
  '科技',
  '進修教育',
  '其它',
];
export const Diet = ['重肉食', '環保素', '信仰素', '不拘'];

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export const chunkArray = (myArray: any[], chunk_size: number) => {
  const arrayLength = myArray.length;
  const tempArray = [];

  for (let index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);

    tempArray.push(myChunk);
  }

  return tempArray;
};

export const successMessageMapping = (message: string) => {
  switch (message) {
    case 'Successfully created : coupon_get_url':
    case 'Successfully get transfer coupon':
    case 'Successfully get commodity transfer':
      return '獲得票券';
    default:
      return '獲得票券';
  }
};

export const errorMessageMapping = (message: string, defaultMessage?: string) => {
  switch (message) {
    case 'this coupon during the inactive period':
      return '此票券已逾期';
    case 'transfer over':
    case 'over coupon event allow limit':
      return '此票券已全數領取完畢';
    case 'user had got this coupon from url before':
      return '您已領過此票券';
    case 'coupon already in ticket folder':
    case 'commodity already in ticket folder':
      return '這張券已轉贈，快請朋友領取吧';
    case 'coupon has expired':
    case 'commodity has expired':
      return '這張券已過期無法領取';
    case 'commodity transfer over 24hrs':
      return '已超過領券時效';
    case 'transfer cancelled':
    case 'The selected url code is invalid.':
      return '不好意思! 好友已取消轉券';
    case 'coupon info id error':
      return '此票券異常，領取失敗';
    case 'not enough points':
    case 'user_point = 0':
      return '點數不足，無法兌換';
    case 'count for coupon is over the limit.':
      return '已超過此票券兌換數量限制';
    case 'only add amount. No MpsCard level configed':
    case 'only add amount. No member points updated.':
      return '未達發點門檻無法獲得點數消費金額已累積至會員卡';
    case 'You are not qualified to get coupon.':
      return '很抱歉\n您的會員等級尚未符合領券資格，感謝您的參與！';
    case 'The time is over getting settings':
      return '此票券已超過領券期限';
    default:
      if (message.indexOf('not getting coupon time') !== -1) {
        return message.replace('not getting coupon time', '此優惠券將於') + ' 開放領取';
      }
      return defaultMessage || '獲得票券失敗';
  }
};

const { REACT_APP_ENV } = process.env;

export const isInDevelopENV = () => {
  return REACT_APP_ENV === 'dev';
};

export const isInProductionENV = () => {
  return REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'stage';
};

export const formatMoney = (number: number, hideSymbol?: boolean) => {
  if (hideSymbol) {
    return new Intl.NumberFormat('en', {
      maximumFractionDigits: 0,
    }).format(number);
  }
  return `$ ${new Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
  }).format(number)}`;
};
