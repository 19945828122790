import LoadingIndicator from 'components/LoadingIndicator';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { FallbackProps } from 'react-error-boundary';

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

const ChunkErrorFallback: FC<FallbackProps> = ({ error }) => {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\S]+ failed/;
    const cssChunkFailedMessage = /Loading CSS chunk [\S]+ failed/;

    if (
      error?.message &&
      (chunkFailedMessage.test(error.message) || cssChunkFailedMessage.test(error.message))
    ) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return <>{LoadingIndicator}</>;
};
export default ChunkErrorFallback;
