import { ReactComponent as Puzzle } from '../../assets/img_puzzle.svg';
import styles from './index.module.scss';

const LoginFailed = () => {
  return (
    <div className={styles.loginFailed}>
      <Puzzle />
      <div className={styles.description}>
        <p>登入失敗</p>
        <p>請關閉並重新開啟此頁面</p>
      </div>
    </div>
  );
};
export default LoginFailed;
