import type { FC, ReactChild } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Back } from '../../assets/icon-back.svg';
import { ReactComponent as Close } from '../../assets/icon-close.svg';
import styles from './index.module.scss';
export interface HeaderProps {
  title: string;
  leftIcon?: ReactChild;
  hideLeftButton?: boolean;
  rightIcon?: ReactChild;
  hideRightButton?: boolean;
  className?: string;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  headerBorder?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const {
    title,
    leftIcon,
    rightIcon,
    onLeftClick,
    onRightClick,
    className,
    hideLeftButton = false,
    hideRightButton = false,
    headerBorder = false,
  } = props;

  // const location = useLocation();
  const navigate = useNavigate();
  const handleLeft = () => {
    if (onLeftClick) {
      onLeftClick();
      return;
    }
    // console.log(location);

    navigate(-1);
  };
  const handleRight = () => {
    if (onRightClick) {
      onRightClick();
      return;
    }
  };
  return (
    <div className={`${styles.header} ${headerBorder ? styles.border : ''} ${className || ''}`}>
      <div>
        {!hideLeftButton && <button onClick={handleLeft}>{leftIcon ? leftIcon : <Back />}</button>}
      </div>
      <div>
        <h1>{title}</h1>
      </div>
      <div>
        {!hideRightButton && (
          <button onClick={handleRight}>{rightIcon ? rightIcon : <Close />}</button>
        )}
      </div>
    </div>
  );
};

export default Header;
