import { ReactComponent as OpenLINE } from '../../assets/img_openLINE.svg';
import styles from './index.module.scss';

const OpenInLINE = () => {
  return (
    <div className={styles.openInLine}>
      <OpenLINE />
      <div className={styles.description}>
        <p>無法透過此瀏覽器使用 Echoss VIP</p>
        <div className={styles.instruction}>
          <p>請依下方指示設定：</p>
          <p>1. 開啟 LINE App中的「設定」</p>
          <p>2. 進入「LINE Labs」</p>
          <p>3. 關閉「使用預設瀏覽器開啟連結」項目</p>
          <p>完成後，再次點擊開啟連結即可使用</p>
        </div>
      </div>
    </div>
  );
};
export default OpenInLINE;
