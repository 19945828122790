import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '.';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const { search } = useLocation();

  if (!user?.isMember && !user?.isNormal) {
    return <Navigate to={{ pathname: '/benefit', search }} replace />;
  }

  return children;
};
export default RequireAuth;
