/**
 * Memorized Form Context
 *
 * 儲存 註冊表單欄位資料
 * 可以再轉跳頁面回來後 保持原本的state
 */
import * as PropTypes from 'prop-types';
import type { Consumer, Context, FC } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface MemorizedFormProviderProps {
  initializeData?: any;
}
interface MemorizedFormContext {
  memorizedData?: any;
  setMemorizedData: (data: any) => void;
  clearMemorizedData: () => void;
}
type CreateMemorizedFormContext = () => {
  MemorizedFormConsumer: Consumer<MemorizedFormContext>;
  MemorizedFormProvider: FC<MemorizedFormProviderProps>;
  useMemorizedForm: () => MemorizedFormContext;
};
const MemorizedFormProviderPropTypes = {
  children: PropTypes.element.isRequired,
};

const createFormProvider = (context: Context<MemorizedFormContext>) => {
  const FormProvider: FC<MemorizedFormProviderProps> = ({ children, initializeData }) => {
    const [memorizedData, setMemorizedData] = useState(initializeData);
    useEffect(() => {
      setMemorizedData(initializeData);
    }, [initializeData]);
    const clearMemorizedData = () => {
      setMemorizedData(undefined);
    };
    return (
      <context.Provider value={{ memorizedData, setMemorizedData, clearMemorizedData }}>
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  FormProvider.propTypes = MemorizedFormProviderPropTypes;
  return FormProvider;
};
export const createMemorizedFormContext: CreateMemorizedFormContext = () => {
  const context = createContext<MemorizedFormContext>({
    setMemorizedData: () => {},
    clearMemorizedData: () => {},
  });
  context.displayName = 'MemorizedFormContext';

  return {
    MemorizedFormConsumer: context.Consumer,
    MemorizedFormProvider: createFormProvider(context),
    useMemorizedForm: () => useContext(context),
  };
};
export const { MemorizedFormConsumer, MemorizedFormProvider, useMemorizedForm } =
  createMemorizedFormContext();
