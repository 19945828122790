import {
  collectCoupon,
  collectCouponByCode,
  collectProductCouponByCode,
  getCouponWithoutToken,
} from 'api/coupon';
import { useAuth } from 'context/Auth';
import { useBrand } from 'context/Brand';
import { useLiff } from 'context/Liff';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface CollectCouponProps {
  onSuccess: (message: string, redirect: string) => void;
  // onConfirm: () => void;
  onError: (message?: string) => void;
  disable?: boolean;
  collectByUrl?: boolean;
}

const useCollectCoupon = (props: CollectCouponProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const { brandInfo } = useBrand();
  const { onSuccess, onError, disable, collectByUrl } = props;
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const setSearchParamsRef = useRef(setSearchParams);
  const { liff } = useLiff();

  const collectID = useMemo(() => searchParams.get('collect'), [searchParams]);
  const shareCode = useMemo(() => searchParams.get('scode'), [searchParams]);
  const productCode = useMemo(() => searchParams.get('pcode'), [searchParams]);

  const checkCouponShareType = useCallback(
    async (couponId, search) => {
      if (brandInfo?.id) {
        try {
          const { data } = await getCouponWithoutToken(couponId, { brand_id: brandInfo?.id });
          if (data && data.share_type === 1) {
            navigateRef.current({ pathname: '/benefit', search }, { replace: true });
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    [brandInfo],
  );

  const checkFriendShip = useCallback(async () => {
    try {
      const { friendFlag } = await liff.getFriendship();
      if (friendFlag) {
        return true;
      } else {
        navigateRef.current('/addoa', { replace: true });
        return false;
      }
    } catch (error) {
      console.log(error);
      navigateRef.current('/addoa', { replace: true });
      return false;
    }
  }, [liff]);

  const collectCouponByUrl = useCallback(
    async (couponId: string) => {
      try {
        if (user?.isNormal) {
          const needRedirect = await checkCouponShareType(couponId, `collect=${couponId}`);
          if (needRedirect) {
            return;
          }
        }
        const isFriend = await checkFriendShip();
        if (isFriend && user?.accessToken) {
          setSearchParamsRef.current(new URLSearchParams());
          const { data: resp, error } = await collectCoupon(
            { coupon_id: couponId },
            user?.accessToken,
          );
          // console.log(data);
          if (resp) {
            onSuccess(resp.data.message, '/coupon');
          }

          if (error) {
            if (error.response && 'data' in error.response) {
              const { data } = error?.response;
              onError(data.data.message);
            }
          }
        }
      } catch (error) {
        console.log(error);
        onError();
      }
    },
    [user, onSuccess, onError, checkFriendShip, checkCouponShareType],
  );

  const collectShareCoupon = useCallback(
    async (code: string) => {
      try {
        const isFriend = await checkFriendShip();
        if (isFriend && user?.accessToken) {
          setSearchParamsRef.current(new URLSearchParams());
          const { data, error } = await collectCouponByCode(code, user?.accessToken);
          if (data) {
            onSuccess(data.message, '/coupon');
          }
          if (error && error?.response) {
            const { data: errorData } = error?.response;
            onError(errorData.data.message);
          }
        }
      } catch (error) {
        console.log(error);
        onError();
      }
    },
    [user, onSuccess, onError, checkFriendShip],
  );

  const collectShareProductCoupon = useCallback(
    async (code: string) => {
      try {
        const isFriend = await checkFriendShip();
        if (isFriend && user?.accessToken) {
          setSearchParamsRef.current(new URLSearchParams());
          const { data, error } = await collectProductCouponByCode(code, user?.accessToken);
          if (data) {
            onSuccess(data.message, '/coupon/products');
          }
          if (error && error?.response) {
            const { data: errorData } = error?.response;
            onError(errorData.data.message);
          }
        }
      } catch (error) {
        console.log(error);
        onError();
      }
    },
    [user, onSuccess, onError, checkFriendShip],
  );

  useEffect(() => {
    if (!disable && collectByUrl) {
      if (collectID) {
        collectCouponByUrl(collectID);
      }
    }
  }, [disable, collectByUrl, collectID, collectCouponByUrl]);

  useEffect(() => {
    if (!disable) {
      if (shareCode) {
        collectShareCoupon(shareCode);
      }
    }
  }, [disable, collectByUrl, shareCode, collectShareCoupon]);

  useEffect(() => {
    if (!disable) {
      if (productCode) {
        collectShareProductCoupon(productCode);
      }
    }
  }, [disable, collectByUrl, productCode, collectShareProductCoupon]);
};

export default useCollectCoupon;
