import type { HeaderProps } from 'components/Header';
import Header from 'components/Header';
import LoadingIndicator from 'components/LoadingIndicator';
import MenuTabBar from 'components/MenuTabBar';
import { createContext, Suspense, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface LayoutProps extends HeaderProps {
  headerBorder: boolean;
  hideHeader: boolean;
  hideMenu: boolean;
}
type LayoutContextType = {
  setLayoutProps?: (value: LayoutProps) => void;
};
const LayoutContext = createContext<LayoutContextType>({ setLayoutProps: undefined });
export function useLayout() {
  return useContext<LayoutContextType>(LayoutContext);
}
const Layout = ({ pre }: { pre?: boolean }) => {
  const [layoutProps, setLayoutProps] = useState<LayoutProps>({
    title: '',
    headerBorder: false,
    hideHeader: false,
    hideMenu: false,
    hideLeftButton: true,
    hideRightButton: true,
  });

  return (
    <LayoutContext.Provider value={{ setLayoutProps }}>
      {!layoutProps.hideHeader && (
        <Header
          title={layoutProps.title}
          hideLeftButton={layoutProps.hideLeftButton}
          hideRightButton={layoutProps.hideRightButton}
          headerBorder={layoutProps.headerBorder}
          onLeftClick={layoutProps.onLeftClick}
          onRightClick={layoutProps.onRightClick}
          leftIcon={layoutProps.leftIcon}
          rightIcon={layoutProps.rightIcon}
        />
      )}
      <Suspense fallback={LoadingIndicator}>
        <Outlet />
      </Suspense>
      {!layoutProps.hideMenu && <MenuTabBar />}
    </LayoutContext.Provider>
  );
};
export default Layout;
