/**
 * Liff Context
 *
 * Line liff 的 context
 * 主要為判斷是否已用line登入，並獲取id token 做登入用
 * 與是否在Liff Browser內開啟
 *
 * 正式環境
 * 會Block非Liff Browser開啟網頁的使用者
 *
 * 測試環境
 * 開啟自動導向line login (endpoint需配合ngrok之類的套件)
 *
 * API詳情 請參考 @https://developers.line.biz/en/reference/liff/#client-api
 */
import liff from '@line/liff';
import type { Liff } from '@line/liff/exports';
import * as PropTypes from 'prop-types';
import type { Consumer, Context, FC } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

declare global {
  interface Window {
    liff?: Liff;
  }
}
interface LiffProviderProps<T> {
  liffId: string;
  search?: string;
  stubEnabled?: boolean | Partial<T>;
}
interface LiffContext {
  error?: unknown;
  isLoggedIn: boolean;
  liff: typeof liff;
  ready: boolean;
  decodedIdToken?: { picture?: string; name?: string; sub?: string } | null | undefined;
  idToken: string | null | undefined;
  accessToken: string | null | undefined;
  isInClient: boolean;
}
type CreateLiffContext = <T extends Liff>() => {
  LiffConsumer: Consumer<LiffContext>;
  LiffProvider: FC<LiffProviderProps<T>>;
  useLiff: () => LiffContext;
};

const initLiff = async <T extends Liff>({ liffId }: LiffProviderProps<T>) => {
  try {
    await liff.init({ liffId });
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('liff.state')) {
      console.log('wait for liff to redirect url');
      await new Promise((resolve) => {});
      return { ready: false };
    }
    return { ready: true };
  } catch (error) {
    console.log(error);
    return { error, ready: false };
  }
};
const LiffProviderPropTypes = {
  children: PropTypes.element.isRequired,
  // liffId: PropTypes.string.isRequired,
};

const createLiffProvider = <T extends Liff>(context: Context<LiffContext>) => {
  const LiffProvider: FC<LiffProviderProps<T>> = ({ children, liffId }) => {
    const [liffState, setLiffState] = useState<{
      error?: unknown;
      isLoggedIn: boolean;
      isInClient: boolean;
      ready: boolean;
    }>({
      error: undefined,
      isLoggedIn: false,
      isInClient: false,
      ready: false,
    });

    useEffect(() => {
      (async () => {
        if (liffId) {
          const { error, ready } = await initLiff({
            liffId,
          });

          if (!liff.isLoggedIn()) {
            if (localStorage.getItem('kdevmode') === '1') {
              liff.login({ redirectUri: window.location.href });
            }
          }
          setLiffState((prev) => ({
            ...prev,
            isInClient: liff.isInClient(),
            isLoggedIn: liff.isLoggedIn(),
            ready,
            error,
          }));
        } else {
          console.error("doesn't provide liff id");
        }
      })();
    }, [liffId]);

    return (
      <context.Provider
        value={{
          liff,
          decodedIdToken: liffState.isLoggedIn ? liff.getDecodedIDToken() : undefined,
          idToken: liffState.isLoggedIn ? liff.getIDToken() : undefined,
          accessToken: liffState.isLoggedIn ? liff.getAccessToken() : undefined,
          ...liffState,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LiffProvider.propTypes = LiffProviderPropTypes;
  return LiffProvider;
};

export const createLiffContext: CreateLiffContext = () => {
  const context = createContext<LiffContext>({
    isLoggedIn: false,
    liff,
    decodedIdToken: undefined,
    idToken: undefined,
    accessToken: undefined,
    ready: false,
    isInClient: false,
  });
  context.displayName = 'LiffContext';

  return {
    LiffConsumer: context.Consumer,
    LiffProvider: createLiffProvider(context),
    useLiff: () => useContext(context),
  };
};

export const { LiffConsumer, LiffProvider, useLiff } = createLiffContext<Liff>();
