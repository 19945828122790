import '@mui/lab/themeAugmentation';

import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@mui/material/styles';
import Maintenance from 'components/Maintenance';
import NotFound from 'components/NotFound';
import { useBrand } from 'context/Brand';
import { LiffProvider } from 'context/Liff';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import AuthWrapper from './AuthWrapper';

const ThemeWrapper = () => {
  const { error, initializing, loading, retry, brandInfo } = useBrand();
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: brandInfo && brandInfo.menu_color ? brandInfo.menu_color : '#08bf5b',
          },
          error: {
            main: '#f44336',
          },
        },
        components: {
          MuiInput: {
            styleOverrides: {
              root: {
                width: '100%',
                padding: '8px 0px',
              },
              input: {
                fontSize: 17,
                letterSpacing: 0.34,
                height: 24,
                margin: 0,
                padding: 0,
                color: '#4a494a',
              },
              underline: {
                '&:before': {
                  borderWidth: '1px !important',
                },
                '&:after': {
                  borderWidth: 1,
                },
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                width: '100%',
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                height: 24,
                '&:focus': {
                  background: 'none',
                  backgroundColor: 'transparent',
                },
              },
              iconOpen: {
                transform: 'rotate(180deg)',
                right: 0,
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#00000033',
              },
              formControl: {
                transform: 'translate(0, 32px) scale(1)',
              },
              shrink: {
                transform: 'translate(0, 1.5px) scale(0.75)',
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: '#00000033',
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                borderRadius: '50% !important',
                backgroundColor: 'white',
                '&.Mui-selected': {
                  color: 'white !important',
                },
              },
            },
          },
          // MuiDialog: {
          //   styleOverrides: {
          //     root: {
          //       '& .PrivatePickersToolbar-dateTitleContainer': {
          //         '& h4': {
          //           // fontSize: 25,
          //         },
          //         '& button': {
          //           // fontSize: 25,
          //         },
          //       },
          //     },
          //   },
          // },
          MuiMonthPicker: {
            styleOverrides: {
              root: {
                '& button': {
                  border: 'none',
                  background: 'none',
                },
              },
            },
          },
          MuiYearPicker: {
            styleOverrides: {
              root: {
                '& button': {
                  '&.Mui-selected': {
                    color: 'white',
                  },
                },
              },
            },
          },
        },
      }),

    // eslint-disable-next-line
    [brandInfo],
  );
  useEffect(() => {
    if (brandInfo && brandInfo.menu_color) {
      document.documentElement.style.setProperty('--theme-color', brandInfo.menu_color);
      document.documentElement.style.setProperty('--tab-color', brandInfo.menu_color);
    }
  }, [brandInfo]);

  if (initializing) {
    return null;
  }

  if (error?.response?.status === 503) {
    return <Maintenance retryFn={retry} loading={loading} />;
  }

  if (error || !brandInfo) {
    return <NotFound retryFn={retry} loading={loading} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{brandInfo?.name}</title>
        <meta property="og:image" content={'/apple-touch-icon.png'} />
      </Helmet>
      <LiffProvider liffId={brandInfo?.liff_id}>
        <AuthWrapper />
      </LiffProvider>
    </ThemeProvider>
  );
};

export default ThemeWrapper;
