import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ChunkErrorFallback from 'components/ChunkErrorFallback';
import Dialog from 'components/Dialog';
import LoadingIndicator from 'components/LoadingIndicator';
import LoginFailed from 'components/LoginFailed';
import { useAuth } from 'context/Auth';
import { useBrand } from 'context/Brand';
import useCollectCoupon from 'hooks/useCollectCoupon';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';
import { errorMessageMapping, successMessageMapping } from 'utils';

import routes from './normalRoutes';

const { REACT_APP_SENTRY_ENV } = process.env;
const queryClient = new QueryClient();

const Routes: FC = () => {
  const { error: authError, logging, user } = useAuth();
  const [collectDialog, setCollectDialog] = useState<{
    visible: boolean;
    success: boolean;
    redirect?: string;
    msg: string;
  }>({
    visible: false,
    success: false,
    msg: '',
  });
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { brandInfo } = useBrand();

  const [searchParams] = useSearchParams();
  const collectID = useMemo(() => searchParams.get('collect'), [searchParams]);
  const productID = useMemo(() => searchParams.get('product'), [searchParams]);

  useEffect(() => {
    if (!pathname.startsWith('/benefit') && !pathname.startsWith('/register')) {
      if (!!collectID && !pathname.startsWith('/coupon')) {
        navigate(`/coupon?collect=${collectID}`);
      }

      if (!!productID) {
        navigate(`/product/${productID}`);
      }
    }
  }, [collectID, navigate, pathname, productID]);

  const element = useRoutes(
    routes(
      {
        menuID: brandInfo?.menu[0].id,
        isMember: user?.isMember || user?.isNormal,
        isNormal: user?.isNormal,
      },
      search,
    ),
  );

  const routingInstrumentation = useRoutingInstrumentation();

  useEffect(() => {
    const browserTracing = new BrowserTracing({
      routingInstrumentation,
    });
    init({
      dsn: 'https://07b2dff7a1d248a8b51813900818645a@o1056068.ingest.sentry.io/6042259',
      integrations: [browserTracing],
      environment: REACT_APP_SENTRY_ENV,
      normalizeDepth: 10,
      tracesSampleRate: 1.0,
      denyUrls: [/https?:\/\/developer\.echoss\.co\.kr/i],
      ignoreErrors: [
        "TypeError: null is not an object (evaluating 'document.getElementById('modal-stamp').classList')",
        "TypeError: Cannot read property 'classList' of null",
        "TypeError: Cannot read properties of null (reading 'classList')",
        "TypeError: null is not an object (evaluating 'document[_0x1c96[635]](_0x1c96[605])[_0x1c96[607]]')",
        "TypeError: null is not an object (evaluating 'document[_0x1c96[635]](_0x1c96[628])[_0x1c96[633]]')",
        "TypeError: Cannot read properties of null (reading 'style')",
        'SDK 啟動失敗，請重新開啟 App',
        '要蓋章時，請旋轉螢幕成為直立方向',
        'For more accurate approval, enter once more. Please stamp again.(03)',
        '因需精確的認證，請再試一次。(03)',
        '初始化失敗。',
        '網路無法連線，請連上 WI-FI 或行動數據後再試一次',
      ],
    });
  }, [routingInstrumentation]);

  const onSuccess = useCallback((message: string, redirect: string) => {
    const msg = successMessageMapping(message);
    setCollectDialog({ visible: true, success: true, msg, redirect });
  }, []);

  const onError = useCallback((message?: string) => {
    const msg = errorMessageMapping(message || '');
    setCollectDialog({ visible: true, success: false, msg });
  }, []);

  useCollectCoupon({
    onSuccess,
    onError,
    disable: !user?.isLoggedIn || logging || !!authError,
    collectByUrl: !collectID,
  });

  const onConfirm = () => {
    if (collectDialog?.success) {
      navigate(collectDialog.redirect || '/coupon', { replace: true });
      setCollectDialog({ ...collectDialog, visible: false });
    } else {
      navigate(`${pathname}`, { replace: true });
      setCollectDialog({ ...collectDialog, visible: false });
    }
  };

  const onCollectConfirm = () => {
    navigate(`/coupon/byurl?collect=${collectID}`, { replace: true });
  };

  const onCollectCancel = () => {
    navigate(`${pathname}`, { replace: true });
  };

  if (logging) {
    return LoadingIndicator;
  }

  if (authError) {
    return <LoginFailed />;
  }

  if (!user) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ChunkErrorFallback}>
        {element}
        <Dialog
          visible={
            !!collectID &&
            !!user.id &&
            !pathname.includes('benefit') &&
            !pathname.includes('register')
          }
          cancelable={true}
          onConfirm={onCollectConfirm}
          onCancel={onCollectCancel}
          message={'確定領取優惠券？'}
          okText={'確定'}
          cancelText={'取消'}
        />
        <Dialog visible={collectDialog.visible} onConfirm={onConfirm} message={collectDialog.msg} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default Routes;
