import Layout from 'components/Layout';
import LoadingIndicator from 'components/LoadingIndicator';
import RequireAuth from 'context/Auth/requireAuth';
import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const CollectPaymentHistory = lazy(() => import('pages/MemberCard/collectPaymentHistory'));
const BatchCode = lazy(() => import('pages/BatchDeduct/batchCode'));
const BatchDeduct = lazy(() => import('pages/BatchDeduct'));
const MpsPointInfo = lazy(() => import('pages/MemberCard/mpsPointInfo'));
const PointsRights = lazy(() => import('pages/MemberCard/pointsRights'));
const Coupon = lazy(() => import('pages/Coupon'));
const CouponInfo = lazy(() => import('pages/Coupon/couponInfo'));
const CouponHistory = lazy(() => import('pages/Coupon/couponHistory'));
const BalanceCoupons = lazy(() => import('pages/Coupon/balanceCoupons'));
const BalanceCouponInfo = lazy(() => import('pages/Coupon/balanceCouponInfo'));
const BalanceCouponLog = lazy(() => import('pages/Coupon/balanceCouponLog'));
const BalanceCouponUsed = lazy(() => import('pages/Coupon/balanceCouponUsed'));
const BalanceCouponExpired = lazy(() => import('pages/Coupon/balanceCouponExpired'));
const Register = lazy(() => import('pages/Register'));
const Me = lazy(() => import('pages/Me'));
const Home = lazy(() => import('pages/Home'));
const HomeInfo = lazy(() => import('pages/Home/info'));
const MemberCard = lazy(() => import('pages/MemberCard'));
const MpsCollectPoint = lazy(() => import('pages/MemberCard/mpsCollectPoint'));
const MpsDeductPoint = lazy(() => import('pages/MemberCard/mpsDeductPoint'));
const MpsSharePoint = lazy(() => import('pages/MemberCard/mpsSharePoint'));
const MpsPointsHistory = lazy(() => import('pages/MemberCard/mpsPointHistory'));
const ModifyProfile = lazy(() => import('pages/ModifyProfile'));
const ProductCouponInfo = lazy(() => import('pages/Coupon/productCouponInfo'));
const CouponNumberList = lazy(() => import('pages/Coupon/couponNumber'));
const ShareCoupon = lazy(() => import('pages/ShareCoupon'));
const PointCard = lazy(() => import('pages/PointCard'));
const Terms = lazy(() => import('pages/Terms'));
const Privacy = lazy(() => import('pages/Privacy'));
const Store = lazy(() => import('pages/OnlineStore'));
const Product = lazy(() => import('pages/Product'));
const ShoppingNote = lazy(() => import('pages/ShoppingNote'));
const SearchProduct = lazy(() => import('pages/SearchProduct'));
const Transactions = lazy(() => import('pages/Transactions'));
const Transaction = lazy(() => import('pages/Transactions/transaction'));
const Cart = lazy(() => import('pages/Cart'));
const PointCardSingle = lazy(() => import('pages/PointCard/single'));
const PointsHistory = lazy(() => import('pages/PointsHistory'));
const PointInfo = lazy(() => import('pages/PointsHistory/pointInfo'));
const RedeemCoupon = lazy(() => import('pages/RedeemCoupon'));
const RedeemPoint = lazy(() => import('pages/RedeemPoint'));
const CollectPoint = lazy(() => import('pages/CollectPoint'));
const Coupons = lazy(() => import('pages/Coupon/coupons'));
const Used = lazy(() => import('pages/Coupon/couponUsed'));
const Expired = lazy(() => import('pages/Coupon/couponExpired'));
const Shared = lazy(() => import('pages/Coupon/couponShared'));
const Received = lazy(() => import('pages/Coupon/couponReceived'));
const Activity = lazy(() => import('pages/Home/activity'));
const Announcement = lazy(() => import('pages/Home/announcement'));
const Benefit = lazy(() => import('pages/MemberCard/benefit'));
const BenefitDetail = lazy(() => import('pages/MemberCard/benefitDetail'));
const AddOA = lazy(() => import('pages/AddOA'));
const RegisterBenefit = lazy(() => import('pages/RegisterBenefit'));
const PaymentHistory = lazy(() => import('pages/PaymentHistory'));
const PaymentInfo = lazy(() => import('pages/PaymentHistory/paymentInfo'));
const CouponCollectByUrl = lazy(() => import('pages/Coupon/couponCollectByUrl'));

const MemberArea = lazy(() => import('pages/MemberArea'));
const MemberAreaJourney = lazy(() => import('pages/MemberAreaJourney'));
const MemberAreaCouponHistory = lazy(() => import('pages/MemberAreaCouponHistory'));
const MemberAreaPointsHistory = lazy(() => import('pages/MemberAreaPointsHistory'));
const MemberAreaInvoiceCarrier = lazy(() => import('pages/InvoiceCarrier'));
const MemberAreaPointsInfo = lazy(
  () => import('pages/MemberAreaPointsHistory/MemberAreaPointsInfo'),
);
const MemberAreaBenefit = lazy(() => import('pages/MemberBenefit'));
const MemberPointHistory = lazy(() => import('pages/MemberPoint'));
const MemberPointInfo = lazy(() => import('pages/MemberPoint/MemberPointInfo'));
const StoreOrderHistory = lazy(() => import('pages/StoreOrders'));
const StoreOrderInfo = lazy(() => import('pages/StoreOrders/StoreOrderInfo'));

const getHomePage = (menuID?: string, search?: string) => {
  switch (menuID) {
    case 'member_card':
      return <Navigate to={{ pathname: 'membercard', search }} replace />;
    case 'loyalty_card':
      return <Navigate to={{ pathname: 'pointcard', search }} replace />;
    case 'coupon':
      return <Navigate to={{ pathname: 'coupon', search }} replace />;
    // case 'my':
    //   return <Navigate to={{ pathname: 'me', search }} replace />;
    case 'store':
      return <Navigate to={{ pathname: 'store', search }} replace />;
    case 'member_area':
      return <Navigate to={{ pathname: 'memberarea', search }} replace />;
    case 'home':
    default:
      return <Navigate to={{ pathname: 'home', search }} replace />;
  }
};

const routes = (
  {
    menuID,
    isMember = false,
    isNormal = false,
  }: { menuID?: string; isMember?: boolean; isNormal?: boolean },
  search?: string,
) => [
  {
    path: 'register',
    element:
      isMember && !isNormal ? (
        <Navigate to={{ pathname: '/', search }} replace />
      ) : (
        <Suspense fallback={LoadingIndicator}>
          <Register />
        </Suspense>
      ),
  },
  {
    path: 'terms',
    element: (
      <Suspense fallback={LoadingIndicator}>
        <Terms />
      </Suspense>
    ),
  },
  {
    path: 'privacy',
    element: (
      <Suspense fallback={LoadingIndicator}>
        <Privacy />
      </Suspense>
    ),
  },
  {
    path: 'benefit',
    element: (
      <Suspense fallback={LoadingIndicator}>
        <RegisterBenefit />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      { index: true, element: getHomePage(menuID, search) },
      {
        path: 'membercard',
        element: isNormal ? <Navigate to={{ pathname: '/benefit', search }} replace /> : <Outlet />,
        children: [
          {
            index: true,
            element: <MemberCard />,
          },
          { path: 'benefit', element: <Benefit /> },
          { path: 'benefit/:memberDeckId', element: <BenefitDetail /> },
          { path: 'pointsrights', element: <PointsRights /> },
          { path: 'collect/point', element: <MpsCollectPoint /> },
          { path: 'deduct/point', element: <MpsDeductPoint /> },
          {
            path: 'collect/paymenthistory',
            element: <CollectPaymentHistory />,
          },
          { path: 'share/point', element: <MpsSharePoint /> },
          // {
          //   path: 'history',
          //   children: [
          //     {
          //       path: 'point',
          //       children: [
          //         { index: true, element: <MpsPointsHistory /> },
          //         {
          //           path: ':pointID',
          //           element: <MpsPointInfo />,
          //         },
          //       ],
          //     },
          //     {
          //       path: '*',
          //       element: <Navigate to={{ pathname: '/membercard/point', search }} replace />,
          //     },
          //   ],
          // },
          { path: 'redeem/coupon', element: <RedeemCoupon isMps /> },
          { path: '*', element: <Navigate to={{ pathname: '/membercard', search }} replace /> },
        ],
      },
      {
        path: 'pointcards',
        element: <PointCard />,
        children: [
          {
            index: true,
            element: <Navigate to={{ pathname: '/pointcards/bookmark', search }} replace />,
          },
          {
            path: 'all',
            element: <Navigate to={{ pathname: '/pointcards/all', search }} replace />,
          },
          {
            path: 'bookmark',
            element: <Navigate to={{ pathname: '/pointcards/bookmark', search }} replace />,
          },
          {
            path: '*',
            element: <Navigate to={{ pathname: '/pointcards/bookmark', search }} replace />,
          },
        ],
      },
      {
        path: 'pointcard',
        children: [
          {
            path: ':pointCardID',
            children: [
              {
                index: true,
                element: <PointCardSingle />,
              },
              {
                path: 'history',
                children: [
                  {
                    index: true,
                    element: <PointsHistory />,
                  },
                  {
                    path: ':pointID',
                    element: <PointInfo />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/pointcard', search }} replace />,
                  },
                ],
              },
              {
                path: 'redeem',
                element: isNormal ? (
                  <Navigate to={{ pathname: '/benefit', search }} replace />
                ) : (
                  <Outlet />
                ),
                children: [
                  {
                    path: 'coupon',
                    element: <RedeemCoupon />,
                  },
                  {
                    path: 'point',
                    element: <RedeemPoint />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/pointcard', search }} replace />,
                  },
                ],
              },
              {
                path: 'collect',
                element: isNormal ? (
                  <Navigate to={{ pathname: '/benefit', search }} replace />
                ) : (
                  <Outlet />
                ),
                children: [
                  {
                    path: 'amount',
                    element: <CollectPoint type="amount" />,
                  },
                  {
                    path: 'point',
                    element: <CollectPoint type="point" />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/pointcard', search }} replace />,
                  },
                ],
              },
            ],
          },
          {
            index: true,
            path: '*',
            element: <Navigate to={{ pathname: '/pointcards/bookmark', search }} replace />,
          },
        ],
      },
      // {
      //   path: 'me',
      //   children: [
      //     {
      //       index: true,
      //       element: <Me pre={isNormal} />,
      //     },
      //     {
      //       path: 'modify',
      //       element: <ModifyProfile />,
      //     },
      //     {
      //       path: 'payment',
      //       children: [
      //         {
      //           index: true,
      //           element: <PaymentHistory />,
      //         },
      //         {
      //           path: ':paymentID',
      //           element: <PaymentInfo />,
      //         },
      //       ],
      //     },
      //     {
      //       path: '*',
      //       element: <Navigate to={{ pathname: '/me', search }} replace />,
      //     },
      //   ],
      // },
      {
        path: 'coupon',
        element: <Coupon pre={isNormal} />,
        children: [
          {
            index: true,
            element: <Navigate to={{ pathname: '/coupon/discounts', search }} replace />,
          },
          {
            path: 'discounts',
            children: [
              { index: true, element: <Coupons /> },
              {
                path: 'history',
                element: <CouponHistory />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                  {
                    path: 'used',
                    element: <Used />,
                  },
                  {
                    path: 'expired',
                    element: <Expired />,
                  },
                  {
                    path: 'shared',
                    element: <Shared />,
                  },
                  {
                    path: 'received',
                    element: <Received typeName="coupon" />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                ],
              },
              {
                path: 'detail',
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: '/coupon/discounts', search }} replace />,
                  },
                  {
                    path: ':couponID',
                    element: <CouponInfo />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/coupon/discounts', search }} replace />,
                  },
                ],
              },
            ],
          },

          {
            path: 'products',
            children: [
              { index: true, element: <Coupons onlyProductCoupon /> },
              {
                path: 'history',
                element: <CouponHistory />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                  {
                    path: 'used',
                    element: <Used isProductCoupon />,
                  },
                  {
                    path: 'expired',
                    element: <Expired isProductCoupon />,
                  },
                  {
                    path: 'shared',
                    element: <Shared isProductCoupon typeName="NORMAL" />,
                  },
                  {
                    path: 'received',
                    element: <Received typeName="NORMAL" />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                ],
              },
              {
                path: 'detail',
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: '/coupon/products', search }} replace />,
                  },
                  {
                    path: ':couponID',
                    element: <ProductCouponInfo />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/coupon/products', search }} replace />,
                  },
                ],
              },
            ],
          },
          {
            path: 'balance',
            children: [
              {
                index: true,
                element: <BalanceCoupons />,
              },
              {
                path: 'history',
                element: <CouponHistory />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                  {
                    path: 'used',
                    element: <BalanceCouponUsed />,
                  },
                  {
                    path: 'expired',
                    element: <BalanceCouponExpired />,
                  },
                  {
                    path: 'shared',
                    element: <Shared isProductCoupon typeName="CASH" />,
                  },
                  {
                    path: 'received',
                    element: <Received typeName="CASH" />,
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: 'used', search }} replace />,
                  },
                ],
              },
              {
                path: 'detail',
                children: [
                  {
                    index: true,
                    element: <Navigate to={{ pathname: '/coupon/balance', search }} replace />,
                  },
                  {
                    path: ':couponID',
                    children: [
                      {
                        index: true,
                        element: <BalanceCouponInfo />,
                      },
                      {
                        path: 'log',
                        element: <BalanceCouponLog />,
                      },
                    ],
                  },
                  {
                    path: '*',
                    element: <Navigate to={{ pathname: '/coupon/balance', search }} replace />,
                  },
                ],
              },
            ],
          },
          {
            path: 'byurl',
            element: <CouponCollectByUrl />,
          },

          {
            path: '*',
            element: <Navigate to={{ pathname: '/coupon/discounts', search }} replace />,
          },
        ],
      },

      {
        path: 'coupon/share',
        element: <ShareCoupon />,
      },
      {
        path: 'coupon/number',
        element: <CouponNumberList />,
      },
      {
        path: 'home',
        element: <Home />,
        children: [
          {
            index: true,
            element: <Navigate to={{ pathname: '/home/activity', search }} replace />,
          },
          {
            path: 'activity',
            element: <Activity />,
          },
          {
            path: 'announcement',
            element: <Announcement />,
          },
          { path: '*', element: <Navigate to={{ pathname: '/home/activity', search }} replace /> },
        ],
      },
      {
        path: 'home/activity/:newsID',
        element: <HomeInfo isActivity />,
      },
      {
        path: 'home/announcement/:newsID',
        element: <HomeInfo />,
      },
      {
        path: 'store',
        element: <Store />,
      },
      {
        path: 'product/:productID',
        element: <Product />,
      },
      {
        path: 'shoppingnotes',
        element: <ShoppingNote />,
      },
      {
        path: 'search',
        element: <SearchProduct />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'transaction/:orderID',
        element: <Transaction />,
      },
      {
        path: 'cart',
        element: <Cart />,
      },
      {
        path: 'addoa',
        element: <AddOA />,
      },
      {
        path: 'batch',
        children: [
          {
            index: true,
            element: <BatchDeduct />,
          },
          {
            path: 'code',
            element: <BatchCode />,
          },
          { path: '*', element: <Navigate to={{ pathname: '/home/activity', search }} replace /> },
        ],
      },
      {
        path: 'memberarea',
        children: [
          {
            index: true,
            element: <MemberArea />,
          },
          {
            path: 'journey',
            element: <MemberAreaJourney />,
          },
          {
            path: 'payment',
            children: [
              {
                index: true,
                element: <PaymentHistory />,
              },
              {
                path: ':paymentID',
                element: <PaymentInfo />,
              },
              {
                path: '*',
                element: <Navigate to={{ pathname: '/memberarea/payment', search }} replace />,
              },
            ],
          },
          {
            path: 'storeorder',
            children: [
              {
                index: true,
                element: <StoreOrderHistory />,
              },
              {
                path: ':storeorderUUID',
                element: <StoreOrderInfo />,
              },
              {
                path: '*',
                element: <Navigate to={{ pathname: '/memberarea/storeorder', search }} replace />,
              },
            ],
          },
          //會員專區－會員點數
          {
            path: 'memberPoint',
            children: [
              {
                index: true,
                element: <MemberPointHistory />,
              },
              {
                path: 'memberPointInfo',
                element: <MemberPointInfo />,
              },
              {
                path: '*',
                element: <Navigate to={{ pathname: '/memberarea/memberPoint', search }} replace />,
              },
            ],
          },
          //會員專區－活動集數
          {
            path: 'pointHistory',
            children: [
              {
                index: true,
                element: <MemberAreaPointsHistory />,
              },
              {
                path: 'pointHistoryInfo',
                element: <MemberAreaPointsInfo />,
              },
              {
                path: '*',
                element: <Navigate to={{ pathname: '/memberarea/pointHistory', search }} replace />,
              },
            ],
          },
          // 會員專區－票券記錄
          {
            path: 'couponHistory',
            element: <MemberAreaCouponHistory />,
          },
          // 會員專區－發票載具
          {
            path: 'invoiceCarrier',
            element: <MemberAreaInvoiceCarrier />,
          },
          {
            path: 'me',
            children: [
              { index: true, element: <Me pre={isNormal} /> },
              {
                path: 'modify',
                element: <ModifyProfile />,
              },
              {
                path: '*',
                element: <Navigate to={{ pathname: '/memberarea/me', search }} replace />,
              },
            ],
          },
          {
            path: 'benefit',
            element: <MemberAreaBenefit />,
          },
          {
            path: '*',
            element: <Navigate to={{ pathname: '/memberarea', search }} replace />,
          },
        ],
      },
      { path: '*', element: getHomePage(menuID, search) },
    ],
  },
];

export default routes;
