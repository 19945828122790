import type { AxiosError } from 'axios';

import type { Response } from './index';
import { echossAxios } from './index';

interface MeRes {
  data: MeData;
}
interface MemberCard {
  id: number;
  name: string;
  benefit: string | null;
  lowest_amount: number;
  highest_amount: number;
  level: number;
  image: string | null;
}
export interface MemberBenefit {
  member_card_group?: {
    id?: number;
    name?: string;
    type?: number;
  };
  content?: string;
}
export interface MeData {
  id: number;
  user_name: string;
  user_phone: string;
  created_at: string | null;
  line_id: string;
  user_birth: string;
  user_gender: string;
  user_city: string;
  user_job: string;
  user_edu: string;
  user_email: string;
  user_mrg: string;
  user_diet: string;
  user_hbt: string;
  user_invoice_carrier: string;

  tags: [];
  member_card: MemberCard | null;
  member_card_code?: string;
  total_amount?: number;
  member_card_due_at: Date;
  period_amount: number;
  amount_status: string;
  amount_status_html?: string;
  recent_expired_points?: number;
  recent_expired_at?: Date;
  member_card_updated_at?: Date;
  next_member_card?: string;
  get_mc_source_name?: string;
  // 1=系統 2=手動 3=到期
  get_mc_source?: 1 | 2 | 3;
  get_mc_reason_name?: string;
  // 1=升等 2=降等 3=續會
  get_mc_reason?: 1 | 2 | 3;
  mps_card_points: number;
  status: string;
  member_card_group_id?: number;
}

export type MemberInformation = {
  member: {
    id: number;
    phone_number: string;
    line_id?: string;
    name?: string;
    email?: string;
    birthday?: string;
    gender?: string;
    location?: string;
    occupation?: string;
    education_level?: string;
    marriage_status?: string;
    interest?: string;
    dietary_preference?: string;
    created_at?: string;
    member_card_code?: string;
  };
  tags: {
    source_tag_id?: number;
    source_tag?: number;
    tags?: {
      id: number;
      name: string;
    }[];
    all_tags?: {
      id: number;
      name: string;
    }[];
    pure_tags?: {
      id: number;
      name: string;
    }[];
    coupon_tags?: {
      id: number;
      name: string;
    }[];
    loyalty_card_tags?: {
      id: number;
      name: string;
    }[];
  };
  member_card: {
    id?: number;
    member_card_group_id?: number;
    name?: string;
    image?: string;
    total_amount?: number;
    period_amount?: number;
    period_from?: string;
    period_to?: string;
    reason?: string;
    updated_at?: string;
    next_level?: {
      id?: number;
      name?: string;
      amount?: number;
      amount_by_count?: number;
      count?: number;
    };
  };
  mps_card: {
    id?: number;
    points?: number;
    expiry_points?: number;
    expiry_date?: string;
  };
  functions: {
    amount_only?: boolean;
    amount_to_point?: boolean;
    deduct_to_coupon?: boolean;
    deduct_to_pay?: boolean;
    transfer?: boolean;
  };
};
/**
 * 會員自身資料
 * @param token token
 */
export const getMe = async (token: string): Promise<Response<MeData>> => {
  try {
    const { data } = await echossAxios.request<MeRes>({
      url: '/api/member/me',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface ModifyResponse<T> {
  error?: AxiosError<{
    message: string;
    data: { message: string };
    errors: { [key: string]: string };
  }>;
  data?: T;
}

export const modifyMe = async (
  token: string,
  formData: Partial<MeData>,
): Promise<
  ModifyResponse<{
    data: {
      message: string;
    };
  }>
> => {
  try {
    const { data } = await echossAxios.request<{
      data: {
        message: string;
      };
    }>({
      url: '/api/member/me',
      method: 'PATCH',
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface PaymentHistoryItem {
  id: number;
  source_type_name: string;
  amount: number;
  event: number;
  created_at: string;
}
export interface PaymentHistoryResp {
  error?: AxiosError<{
    data: { message?: string; data?: { message: string }; errors?: { [key: string]: string } };
  }>;
  data?: PaymentHistoryItem[];
  meta?: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

/**
 * 會員消費記錄
 * @param token token
 */
export const getPaymentHistory = async (
  token: string,
  params: {
    page?: number;
    rows?: number;
  },
): Promise<PaymentHistoryResp> => {
  try {
    const { data } = await echossAxios.request<PaymentHistoryResp>({
      url: '/api/member/amount',
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data, meta: data.meta };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface PaymentInfo {
  id: number;
  source_type: number;
  source_type_name: string;
  created_at: string;
  amount: number;
  event: number;
  points: number;
  shop_name: string;
  order_uuid: null;
}

/**
 * 會員消費記錄詳情
 * @param id payment id
 * @param token token
 */
export const getPaymentInfo = async (id: string, token: string): Promise<Response<PaymentInfo>> => {
  try {
    const { data } = await echossAxios.request<{ data: PaymentInfo }>({
      url: `/api/member/amount/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export const queryMemberBenefits = async (token: string) => {
  try {
    const { data } = await echossAxios.request({
      url: '/api/member/member-cards/benefits',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    console.error(e);
    return { success: false, message: e };
  }
};

export const queryMemberInformation = async (token: string) => {
  try {
    const { data } = await echossAxios.request({
      url: '/api/member/member-cards/info',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    console.error(e);
    return { success: false, message: e };
  }
};
