import type { TagFormData } from 'api';
import { tagUser } from 'api';
import LoginFailed from 'components/LoginFailed';
import OpenInLINE from 'components/OpenInLINE';
import { AuthProvider } from 'context/Auth';
import { useBrand } from 'context/Brand';
import { useLiff } from 'context/Liff';
import { MemorizedFormProvider } from 'context/MemorizedForm';
import type { FC } from 'react';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { isInProductionENV } from 'utils';

import AppRoutes from '../routes';

const AuthWrapper: FC = () => {
  const search = window.location.search;
  const { error, isLoggedIn, ready, idToken, isInClient, liff, decodedIdToken } = useLiff();
  const { initializing, brandInfo } = useBrand();
  const searchParams = new URLSearchParams(search);
  const tagID = searchParams.get('tag');
  const redirectUrl = searchParams.get('redirect');

  const handleTagUser = async (formData: TagFormData) => {
    try {
      await tagUser(formData);
    } catch (tagError) {
      console.log(tagError);
    }
  };

  useEffect(() => {
    if (tagID && redirectUrl && brandInfo?.id) {
      if (error) {
        if (
          redirectUrl.indexOf('https://lin.ee') !== -1 ||
          redirectUrl.indexOf('https://liff.line.me') !== -1
        ) {
          window.location.href = redirectUrl;
          return;
        }
        liff.openWindow({
          url: redirectUrl,
          external: true,
        });
        return;
      }
      if (isLoggedIn && decodedIdToken?.sub) {
        handleTagUser({
          tag: tagID,
          line_id: decodedIdToken?.sub,
          brand_id: `${brandInfo?.id}`,
        });
        if (
          redirectUrl.indexOf('https://lin.ee') !== -1 ||
          redirectUrl.indexOf('https://liff.line.me') !== -1
        ) {
          window.location.href = redirectUrl;
          return;
        }
        liff.openWindow({
          url: redirectUrl,
          external: true,
        });
        return;
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn, error]);

  if (!ready || error || initializing) {
    return null;
  }
  if (!isInClient && isInProductionENV() && localStorage.getItem('kdevmode') !== '1') {
    return <OpenInLINE />;
  }
  if (!isLoggedIn) {
    return <LoginFailed />;
  }
  if (redirectUrl && tagID) {
    return null;
  }
  if (brandInfo?.id && idToken) {
    return (
      <AuthProvider brandID={brandInfo?.id} lineToken={idToken}>
        <MemorizedFormProvider>
          <BrowserRouter basename={brandInfo?.identifier}>
            <AppRoutes />
          </BrowserRouter>
        </MemorizedFormProvider>
      </AuthProvider>
    );
  }

  return null;
};

export default AuthWrapper;
