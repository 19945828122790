import { Slide } from '@mui/material';
import type { MenuItem } from 'components/MenuTabBar';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

const MoreMenu = (props: {
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  moreMenu: MenuItem[];
}) => {
  const { visible, toggleVisible, moreMenu } = props;

  return (
    <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
      <div className={styles.moreContainer}>
        <ul>
          {moreMenu.map((menu) => (
            <Link
              key={menu.id}
              to={menu.url ?? ''}
              onClick={() => {
                toggleVisible(false);
              }}
            >
              <li>
                <div className={styles.menuItem}>
                  {menu.icon && menu.icon('white')}
                  <span>{menu.title}</span>
                </div>
              </li>
            </Link>
          ))}
        </ul>
        <div className={styles.cancelContainer}>
          <button
            onClick={() => {
              toggleVisible(false);
            }}
          >
            取消
          </button>
        </div>
      </div>
    </Slide>
  );
};
export default MoreMenu;
