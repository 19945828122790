import '@mui/lab/themeAugmentation';

import styles from 'App.module.scss';
import NotFound from 'components/NotFound';
import { BrandProvider } from 'context/Brand';
import { useCallback, useEffect } from 'react';
import ThemeWrapper from 'wrapper/ThemeWrapper';

if (process.env.REACT_APP_ENV !== 'prod') {
  console.log(
    `%c Your are in ${process.env.REACT_APP_ENV} env !`,
    'background: #222; color: #bada55',
  );
}

function App() {
  const parsedData = window.location.pathname.split('/');
  const brandIdentify = parsedData[1];

  const autoHeightFix = useCallback(() => {
    const windowsVH = window.innerHeight / 100;
    document.documentElement.style.setProperty('--vh', `${windowsVH}px`);
    window.addEventListener('resize', () => {
      const wh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${wh}px`);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      autoHeightFix();
    }, 100);
  }, [autoHeightFix]);

  if (!brandIdentify) {
    return <NotFound retry={false} />;
  }
  return (
    <div className={styles.container}>
      <BrandProvider brandIdentify={brandIdentify}>
        <ThemeWrapper />
      </BrandProvider>
    </div>
  );
}

export default App;
