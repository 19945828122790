import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import styled from '@mui/material/styles/styled';
import type { FC } from 'react';
import { useState } from 'react';
import { createPortal, render } from 'react-dom';

import { ReactComponent as CloseIcon } from '../../assets/icon-close.svg';
import styles from './index.module.scss';

const CloseButton = styled('button')(({ theme }) => ({
  outline: 'none',
  background: 'none',
  border: 'none',
  color: 'black',
  cursor: 'pointer',
  transition: 'color .3s ease',
  width: 24,
  height: 24,
  padding: 0,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.grey[500],
  },
  '& svg': {
    width: 20,
    height: 20,
  },
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
  textAlign: 'right',
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 15px',
}));

const DialogContentText = styled(MuiDialogContentText)(() => ({
  width: 250,
  textAlign: 'center',
  color: 'black !important',
  fontSize: '13px !important',
  marginBottom: 0,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '10px !important',
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    marginLeft: 0,
    marginTop: 10,
  },
}));

export interface CustomDialogProps {
  visible: boolean;
  onConfirm: () => Promise<void> | void;
  message: string;
  cancelable?: boolean;
  onCancel?: () => void;
  onCancelButtonClick?: () => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
}

const CustomDialog: FC<CustomDialogProps> = (props) => {
  const {
    visible,
    onConfirm,
    message,
    cancelable,
    onCancel,
    onCancelButtonClick,
    okText,
    cancelText,
    loading,
  } = props;

  const onClose = onCancel ? onCancel : onConfirm;

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>
        {onClose ? (
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelable && (
          <button
            className={`${styles.actionButton} ${styles.cancel}`}
            onClick={onCancelButtonClick || onCancel}
          >
            {cancelText || '否'}
          </button>
        )}
        <LoadingButton
          loading={loading}
          sx={{
            color: 'white',
            fontSize: 17,
            fontWeight: 'bold',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          className={styles.actionButton}
          onClick={onConfirm}
          variant="contained"
          disableRipple
        >
          {okText || '好'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
const DialogHOC = (props: Omit<CustomDialogProps, 'visible' | 'loading'>) => {
  const { onCancel, onConfirm, ...restProps } = props;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true);
      await onConfirm();
      setLoading(false);
    }
    setOpen(false);
  };

  const handleCancel = async () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <CustomDialog
      visible={open}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      loading={loading}
      {...restProps}
    />
  );
};
export const dialog = (props: Omit<CustomDialogProps, 'visible' | 'loading'>) => {
  const fragment = document.createDocumentFragment();

  return render(createPortal(<DialogHOC {...props} />, document.body), fragment);
};
