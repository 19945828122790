import { CircularProgress } from '@mui/material';

import styles from './index.module.scss';

const LoadingIndicator = (
  <div className={styles.loadingContainer}>
    <CircularProgress />
  </div>
);

export default LoadingIndicator;
